<template>
  <div onselectstart="return false;">
    <div class="content-wrapper">
      <section class="hero">
        <div class="hero-content" data-aos="fade-up">
          <h1 class="gradient-text">创意无限</h1>
          <p>
            创意无限提供高效便捷的创意处理工具，一站式解决图像、文本、视频编辑需求，助您从灵感到成品全流程创作，操作简单精准，保障作品最佳效果，让创意无拘无束。
          </p>
          <div class="cta-buttons">
            <button class="btn primary">
              <a href="https://creatinf.com/RemoveBackground/" style="text-decoration: none; color: inherit;">
                免费试用 </a>
            </button>
            <button class="btn secondary">
              <a href="https://creatinf.com/Learnmore/" style="text-decoration: none; color: inherit;">了解更多</a>
            </button>
          </div>
        </div>
        <div class="hero-video" data-aos="fade-left" data-aos-delay="300">
          <div class="bg"></div>
          <div class="video-container">
            <swiper :options="swiperOptions">
              <swiper-slide v-for="video in heroVideos" :key="video">
                <video autoplay loop muted playsinline>
                  <source :src="video" type="video/mp4">
                </video>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>

      <section v-for="(feature, index) in features" :key="index" class="feature"
        :class="{ 'reverse': index % 2 !== 0 }">
        <div class="feature-content">
          <div class="feature-text" :data-aos="index % 2 === 0 ? 'fade-right' : 'fade-left'">
            <h2 class="gradient-text">{{ feature.title }}</h2>
            <p>{{ feature.description }}</p>
            <div class="feature-buttons">
              <button class="btn primary">
                <a :href="feature.primaryLink" style="text-decoration: none; color: inherit;">免费试用</a>
              </button>
              <button class="btn secondary">
                <a :href="feature.learnMoreLink" style="text-decoration: none; color: inherit;">了解更多</a>
              </button>
            </div>
          </div>
          <div class="feature-image" :data-aos="index % 2 === 0 ? 'fade-left' : 'fade-right'">
            <div class="video-container">
              <swiper :options="swiperOptions">
                <swiper-slide v-for="video in feature.videos" :key="video">
                  <video autoplay loop muted playsinline>
                    <source :src="video" type="video/mp4">
                  </video>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'ContentSection',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const heroVideos = [
    [require('@/assets/video/主页首部视频.mp4')]
      /* [require('@/assets/video/ImageConvert.mp4')] */
      /* 'path-to-your-hero-video-3.mp4', */
    ];

    const features = ref([
      {
        title: '丰富的抠像处理',
        description: '一键抠图，主体明确轻松搞定；复杂场景，鼠标左右键配合也能应对。还支持视频抠像，无需绿幕，一键上传即可。',
        videos: [require('@/assets/video/裁剪.mp4')],
        learnMoreLink: 'https://creatinf.com/Learnmore/guide/one-click-matting.html',
        primaryLink: 'https://creatinf.com/RemoveBackground/'
      },
      {
        title: '图片重对焦',
        description: 'AI智能识别深度，只需点击新对焦点，滑动以调整光圈，即可轻松获得不同景深下的主体图。模拟相机效果，实现突出主体。',
        videos: [require('@/assets/video/重对焦.mp4')],
        learnMoreLink: 'https://creatinf.com/Learnmore/guide/Image-ReFocus.html',
        primaryLink: 'https://creatinf.com/Image_ReFocus/'
      },
      {
        title: '在线编辑器',
        description: '我们的在线编辑器支持透明图层、图像调整。可直接在线二次加工合成，也可上传背景图，结合抠图或重打光图像进行自定义调整，您可以根据需求，轻松在线构建PPT、海报、思维导图。',
        videos: [require('@/assets/video/在线编辑器.mp4')],
        learnMoreLink: 'https://creatinf.com/Learnmore/guide/text-editor.html',
        primaryLink: 'https://creatinf.com/Mind_Map/#/'
      },
      {
        title: '图像放大和去模糊',
        description: '图像放大去模糊，轻松提升画质，细节更清晰。旧照低清或模糊图，技术恢复细节，焕新图像。立即体验，图片重获清晰活力！',
        videos: [require('@/assets/video/SuperResolution.mp4')],
        learnMoreLink: 'https://creatinf.com/Learnmore/guide/image-super-resolution.html',
        primaryLink: 'https://creatinf.com/Super-resolution/'
      },
      {
        title: '额外功能',
        description: 'OCR识别、AI模型交互、格式转换等多种功能，满足您的多样化需求。',
        videos: [require('@/assets/video/Chat.mp4')],
        learnMoreLink: 'https://creatinf.com/Learnmore/guide/AIChat.html',
        primaryLink: 'https://creatinf.com/OCR/'
      }
    ]);

    const swiperOptions = {
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    };

    onMounted(() => {
      gsap.utils.toArray('.feature').forEach((section, i) => {
        const w = section.querySelector('.feature-image');
        const text = section.querySelector('.feature-text');
        const [xImage, xEndImage] = i % 2 === 0 ? ['100%', '0%'] : ['-100%', '0%'];
        const [xText, xEndText] = i % 2 === 0 ? ['-100%', '0%'] : ['100%', '0%'];

        // 图片动画
        gsap.fromTo(w,
          { x: xImage, opacity: 0 },
          {
            x: xEndImage,
            opacity: 1,
            duration: 1,
            ease: "cubic-bezier(0.25, 0.1, 0.25, 1)",
            scrollTrigger: {
              trigger: section,
              start: "top bottom",
              end: "top 70%",
              toggleActions: "play none none none",
              once: true,
            }
          }
        );

        // 文字动画
        gsap.fromTo(text,
          { x: xText, opacity: 0 },
          {
            x: xEndText,
            opacity: 1,
            duration: 1,
            ease: "cubic-bezier(0.25, 0.1, 0.25, 1)",
            scrollTrigger: {
              trigger: section,
              start: "top bottom",
              end: "top 70%",
              toggleActions: "play none none none",
              once: true,
            }
          }
        );
      });
    });

    const scrollTo = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return {
      heroVideos,
      features,
      swiperOptions,
      scrollTo,
    };
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;

}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 35px;
}

.hero,
.feature {
  margin-bottom: 100px;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.feature-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* 加入overflow修正右侧空白，在涉及动画的父容器或目标元素上设置
 overflow: hidden，防止动画初始状态时的溢出影响页面布局： */
 .content-wrapper {
  overflow: hidden;
}

.feature.reverse .feature-content {
  flex-direction: row-reverse;
}

.hero-video,
.feature-image {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  line-height: 0;
}

.feature-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  padding: 40px
}

.video-container {
  position: relative;
  
}

.video-container video {
  object-fit: cover;
}

.hero-video video,
.feature-image video {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.gradient-text {
  background-image: linear-gradient(
    -45deg, 
    #eaec64,
    #4ecdc4, 
    #45b7d1, 
    #ff6b6b,
    #ff6b6b, 
    #f441a5
  );
  background-size: 300% 300%;
  animation: gradientShift 4s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

h1 {
  font-size: 4rem;
  margin-bottom: 20px;
  font-weight: 600;
}

h2 {
  font-size: 3rem;
  margin-bottom: 15px;
  font-weight: 600;
}

p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 30px;
  color: #505050;
  text-align: center;
}

.cta-buttons {
  margin-bottom: 20px;
}

.cta-buttons,
.feature-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.btn {
  position: relative;
  overflow: hidden;
  padding: 15px 30px;
  font-size: 1.1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: all 0.5s;
}

.btn:hover::before {
  left: 100%;
}

.btn.primary {
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg, 
    #ff6b6b, 
    #f441a5, 
    #ffa33b, 
    #4ecdc4, 
    #45b7d1, 
    #ff6b6b
  );
  animation: gradientShift 8s ease infinite;
  color: white;
  border: none;
}
.btn.primary:hover {
  color: #e1f9fa; /* 改变文字颜色 */
}

.btn.secondary {
  background-color: transparent;
  color: #4ecdc4;
  border: 2px solid #4ecdc4;
  transition: all 0.3s ease;
}

.btn.secondary:hover {
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg, 
    rgba(255, 107, 107, 0.2), 
    rgba(78, 205, 196, 0.2), 
    rgba(69, 183, 209, 0.2), 
    rgba(255, 107, 107, 0.2)
  );
  animation: gradientShift 8s ease infinite;
  color: #333;
  border-color: transparent;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.3);
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {

  .hero-content,
  .feature-content,
  .feature-content.reverse {
    flex-direction: column;
  }

  .content-wrapper .feature .feature-content,
  .content-wrapper .feature.reverse .feature-content {
    flex-direction: column !important;
  }

  .feature-content {
    gap: 40px;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1rem;
  }

  .btn {
    padding: 12px 24px;
    font-size: 1rem;
  }

  .feature-text,
  .feature-image {
    width: 100%;
  }
}
</style>